import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import './modals.scss';
import '../../Steps/RequestInfo/request-info.scss';
import { AdminService, AuthService, WLOCreditAPIService } from '../../../services';
import { Check } from '@mui/icons-material';
import { AppRoutes } from '../../../Router';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSignContext } from '../../../hooks/useSignContext';
import { useRequestContext } from '../../../hooks/useRequestContext';
import { typeIdentification } from '../../../__mocks__/data';
import Moment from 'moment';
import { WloCreditoService } from '../../../types';
import { toast } from 'react-hot-toast';
import { string } from 'yup';
import { json } from 'stream/consumers';
import { Document,pdfjs,Page  } from 'react-pdf'
import { link } from 'fs/promises';
import { Console } from 'console';
interface EnterCodeProps {
    
    token: string;
    show: boolean;
    onHide: () => void;
    sendCode: () => void;
    // gotodata: () => void;
    errors?: any;
    values?: any;
}

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function EnterCode(props: EnterCodeProps) {
    const { sign, setSign } = useSignContext();
    const { request, setRequest } = useRequestContext();
    const navigate = useNavigate();
    const { onHide, show, token, sendCode} = props;
    const [counter, setCounter] = useState(300);
    const timeOutCallback = useCallback(() => setCounter((currTimer) => currTimer - 1), []);
    useEffect(() => {
        if (show) {
            counter > 0 && setTimeout(timeOutCallback, 1000);
        }
    }, [counter, timeOutCallback, show]);
    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = 'Se perderá el progreso actual si sales de esta página.';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    const intl = useIntl();
    const defaultCode = new Array(6).fill('');
    const [modalShow, setModalShow] = React.useState(false);
    const [isSucessful, setIsSucessful] = React.useState(false);
    const [errorCode, setErrorCode] = useState<boolean>(false);
    const [viewNewCode, setViewNewCode] = useState<boolean>(false);
    const [code, setCode] = useState<string[]>(defaultCode);
    const [codigoClie, setCodigo] = useState('');
    const resetCode = () => {
        setCounter(300);
        setCode(defaultCode);
        onHide();
    };

    const handleSearchSagrilaft = async () => {
        onHide();
    };
    const [documento, setDocumento] = useState<string>('')

    const verifyGas = async () => {
        let tipodocumento = '';
        let valorpuntajeEntre0y150 = false;
        let presentaRiesgos = false;
        let puntaje = 0;
        let cuotaData = 0;
        let scorepuntaje = 0;
        let scorecygnus = false;
        let enestudio = false;
        let negado = false;
        let valorDataCreditoCodeudor = false;
        let documentRequest:any;
        let sagrilaftRiesgo;
        let responseData;
        let propietario = false;
        let codeudorDatos;
        let pws_codigo = 0;

        if (sign.typeIdentification === '12') tipodocumento = 'ti';
        if (sign.typeIdentification === '13') tipodocumento = 'cc';
        if (sign.typeIdentification === '22') tipodocumento = 'ce';
        if (sign.typeIdentification === '31') tipodocumento = 'nit';
        if (sign.typeIdentification === '41') tipodocumento = 'pa';

        //consulta Estado entidad Tercero
        const estadoTerce = await AdminService.entidadTer('');
        const resultConsultaPersona = await WLOCreditAPIService.consultaSolicitudCygnus({Pws_Identificacion: sign.numIdentification,
            Pws_Tip_Identificacion: sign.typeIdentification!});
        if (estadoTerce.payload) {
            // const items = JSON.parse(localStorage.getItem('identificacion')!);
            // const DatosDeudor = JSON.parse(localStorage.getItem('person')!);
            const DatosDeudor = JSON.parse(localStorage.getItem('solicitud')!);

            if (tipodocumento === 'ti') tipodocumento = '12';
            if (tipodocumento === 'cc') tipodocumento = '13';
            if (tipodocumento === 'ce') tipodocumento = '22';
            if (tipodocumento === 'nit') tipodocumento = '31';
            if (tipodocumento === 'pa') tipodocumento = '41';

            let codeudors: any = {
                Pws_codigo: '',
                Pws_num_solicitud: Number(request.numSol!),
                Pws_Identificacion: DatosDeudor.radicado.Pws_Identificacion,
                Pws_Radic_Codeu: Number(request.numSol!),
                Pws_Tip_Identificacion: tipodocumento,
                Pws_Iden_Code: DatosDeudor.radicado.Pws_Iden_Code,
                Pws_Tip_Identificacion_Code: "",
                Pws_Tip_Codeu: '',
                Pws_Estado: '',
            };
            const allCodeudor = await WLOCreditAPIService.addCodeudor(codeudors);
            pws_codigo = allCodeudor?.payload?.data.datos_codeu?.Datos_codeudor[0]?.Pws_codigo
            codeudorDatos = allCodeudor;

            if (
                allCodeudor.payload.data.Resultado !== '-2' &&
                allCodeudor.payload.data.Resultado !== '-1' &&
                allCodeudor.payload.data.datos_codeu.Datos_codeudor[0].Pws_Tip_Codeu === '2'
            ) {
                propietario = false;
                const propietarioString = propietario.toString();
                const codeudorPropietario = localStorage.setItem('propietario', propietarioString);
                const Requerido = '2';
                const codeudorRequerido = localStorage.setItem('Requerido', Requerido);
                if (tipodocumento === '12') tipodocumento = 'ti';
                if (tipodocumento === '13') tipodocumento = 'cc';
                if (tipodocumento === '22') tipodocumento = 'ce';
                if (tipodocumento === '31') tipodocumento = 'nit';
                if (tipodocumento === '41') tipodocumento = 'pa';

                // consulta sagrilaft
                const data = {
                    datoConsultar: sign.numIdentification!, //19123402
                    tipoDocumento: tipodocumento,
                    numeroSolicitud: request.numSol!, //No tenemos un ws que nos diga el nro de solicitud
                    fechaSolicitud: Moment(new Date()).format('MM/DD/YYYY'),
                    email: `${sign.email!}`,
                };
                //////////////////////////////////////
                if (estadoTerce.payload.data.ListRef.Datos_Referencias[1].s_descripcion_legal == '1') {
                    const response = await WLOCreditAPIService.consultaConsolidada(data);
                    if (response.payload.presentaRiesgo) {
                        sagrilaftRiesgo = '1';
                        presentaRiesgos = true;
                    } else {
                        sagrilaftRiesgo = '0';
                        presentaRiesgos = false;
                    }
                     //consulta datacredito
                        let consulta;
                        if (request.personCygnus?.Pws_Apellidos1 == '') {
                            let sends: WloCreditoService.SolicitudDetalla = {
                                Pws_Num_Solicitud: request.numSol!,
                                Pws_Identificacion: sign.numIdentification!,
                                Pws_Tip_Identificacion: String(
                                    typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.filter(
                                        (valor) => valor.s_codigo == Number(sign.typeIdentification!)
                                    )[0].s_datacredito
                                ),
                                Pws_Perfil_Acceso: '',
                                Pws_Fec_Solicitud: '',
                            };
                            consulta = await WLOCreditAPIService.consultaSolicitudCygnusDetal(sends);
                        }
                        let apellido;
                        if (request.personCygnus?.Pws_Apellidos1 == '') {
                            apellido = consulta.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos1;
                        } else {
                            apellido = request.personCygnus?.Pws_Apellidos1;
                        }
                        const data4 = {
                            identificacion: sign.numIdentification!,
                            primer_apellido: apellido,
                            tipoIdentificacion: String(
                                typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.filter(
                                    (valor) => valor.s_codigo == Number(sign.typeIdentification!)
                                )[0].s_datacredito
                            ),
                        };
                        let response3;
                        let estadoDatacredito = '0';
                        let consultaDatacredito : WloCreditoService.ConsultaDatacreditoCygnus={
                            Pws_Identificacion: sign.numIdentification!,
                            Pws_Tip_Identificacion: "13",
                            Pws_Tip_val:"2",
                        };
                        response3 = await WLOCreditAPIService.consultaDatacredito(consultaDatacredito);
                        console.dir(response3)
                        
                        
                        if (response3.payload.data.Pws_R_s_cod == 1){
                            
                            const jsonObject = JSON.parse(response3.payload.data.datos_datacre.datos_datacredito_consult[0].c_Pws_Xml_Dc)
                            
                            if (jsonObject != null && jsonObject != undefined) {
                                puntaje = Number(jsonObject?.score?.reasonCodes?.[0]);
                                cuotaData = jsonObject?.engineResponse?.find((item: { key: string, value: any }) => item.key === "Gasto_Financiero").value;
                                if (puntaje >= 1 && puntaje < 500) {
                                    estadoDatacredito = '1';
                                } else if (puntaje >= 500) {
                                    estadoDatacredito = '0';
                                }
                                const data5 = {
                                    Pws_num_solicitud: request.numSol!,
                                    Pws_Identificacion: sign.numIdentification!,
                                    Pws_Tip_Identificacion: sign.typeIdentification!,
                                    Pws_Fec_gen: String(response3.payload.data.datos_datacre.datos_datacredito_consult[0].Pws_Fec_gen),             
                                    Pws_estado_resotp: estadoDatacredito,
                                    Pws_Tip_val: '2',
                                    Pws_Puntaje: String(puntaje),
                                    Pws_Entidad_Consul: '4',
                                    Pws_Num_cuodat: String(cuotaData),
                                    Pws_Xml_Dc: JSON.stringify(jsonObject),
                                };
                                const response5 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data5);
                                try{
                                    let valIngreso = jsonObject.engineResponse.find((item: { key: string, value: any }) => item.key === "Valor_Ingreso")?.value;
                                    let cuantoMin = jsonObject.engineResponse.find((item: { key: string, value: any }) => item.key === "Quanto3_Minimo")?.value;
                                    if (valIngreso !== null && valIngreso !== "0") {
                                        resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = valIngreso+".00";
                                    } else if ((valIngreso === null || valIngreso === "0") && 
                                            ( cuantoMin !== null && cuantoMin !== "0")) {
                                        resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = cuantoMin+".00";
                                    }
                                    let ingresos = resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0];
                                    const operFinancieras = {
                                        s_num_solicitud: request.numSol!,
                                        s_identificacion: sign.numIdentification!,
                                        s_tipo_identificacion: sign.typeIdentification,
                                        s_ingreso_principal: ingresos.Pws_Ingreso_Principal,
                                        s_otros_ingresos: ingresos.Pws_otros_ingresos,
                                        s_otros_egresos: ingresos.Pws_Otros_egresos,
                                        s_arriendo: ingresos.Pws_Arriendo,
                                        s_concep_otr_ingre: ingresos.Pws_Concep_otr_ingre,
                                        s_declarante_ren: ingresos.Pws_Declarante_ren,
                                        s_moneda_ext: ingresos.Pws_Moneda_ext,
                                        s_monext_oper: ingresos.Pws_Monext_oper,
                                        s_tip_monext: ingresos.Pws_Tip_monext,
                                        s_cuent_ext: ingresos.Pws_Cuent_ext,
                                        s_cuen_extban: ingresos.Pws_Cuen_extban,
                                        s_cuen_extnum: ingresos.Pws_Cuen_extnum,
                                        s_cuen_extpais: ingresos.Pws_Cuen_extpais,
                                        s_cuen_extciudad: ingresos.Pws_Cuen_extciudad
                                    }
                                    const valorfinancial=await WLOCreditAPIService.addFinancialInformation(operFinancieras);                                
                                }catch(e){
                                }
                            }
                            
                        }else{
                        try{

                            response3 = await WLOCreditAPIService.consultDataCredito2(data4);
                        }catch(e){
                            console.log(e);
                        }
                        if (response3 != null || response3 != undefined) {
                            if (response3.responseData.Fault != null || response3.responseData.Fault != undefined) {
                                puntaje = 0;
                                cuotaData = 0;
                                estadoDatacredito = '2';
                            } else {
                                puntaje = response3.responseData.score.reasonCodes[0];
                                cuotaData = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Gasto_Financiero").value;                           
    
                                if (puntaje >= 1 && puntaje < 500) {
                                    estadoDatacredito = '1';
                                } else if (puntaje >= 500) {
                                    estadoDatacredito = '0';
                                }
                                const resultConsultaPersona = await WLOCreditAPIService.consultaSolicitudCygnus({Pws_Identificacion: sign.numIdentification,
                                    Pws_Tip_Identificacion: sign.typeIdentification!});
                                console.log(resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0]);
                                try{
                                    let valIngreso = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Valor_Ingreso")?.value;
                                    let cuantoMin = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Quanto3_Minimo")?.value;
                                    if (valIngreso !== null && valIngreso !== "0") {
                                        resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = valIngreso+".00";
                                    } else if ((valIngreso === null || valIngreso === "0") && 
                                            ( cuantoMin !== null && cuantoMin !== "0")) {
                                        resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = cuantoMin+".00";
                                    }
                                    let ingresos = resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0];
                                    const operFinancieras = {
                                        s_num_solicitud: request.numSol!,
                                        s_identificacion: sign.numIdentification!,
                                        s_tipo_identificacion: sign.typeIdentification,
                                        s_ingreso_principal: ingresos.Pws_Ingreso_Principal,
                                        s_otros_ingresos: ingresos.Pws_otros_ingresos,
                                        s_otros_egresos: ingresos.Pws_Otros_egresos,
                                        s_arriendo: ingresos.Pws_Arriendo,
                                        s_concep_otr_ingre: ingresos.Pws_Concep_otr_ingre,
                                        s_declarante_ren: ingresos.Pws_Declarante_ren,
                                        s_moneda_ext: ingresos.Pws_Moneda_ext,
                                        s_monext_oper: ingresos.Pws_Monext_oper,
                                        s_tip_monext: ingresos.Pws_Tip_monext,
                                        s_cuent_ext: ingresos.Pws_Cuent_ext,
                                        s_cuen_extban: ingresos.Pws_Cuen_extban,
                                        s_cuen_extnum: ingresos.Pws_Cuen_extnum,
                                        s_cuen_extpais: ingresos.Pws_Cuen_extpais,
                                        s_cuen_extciudad: ingresos.Pws_Cuen_extciudad
                                    }
                                    const valorfinancial=await WLOCreditAPIService.addFinancialInformation(operFinancieras);                                
                                }catch(e){
                                }
                            }
                        } else {
                            puntaje = response3.responseData.score.reasonCodes[0];
                            cuotaData = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Gasto_Financiero").value;                           
                            estadoDatacredito = '3';
                            const resultConsultaPersona = await WLOCreditAPIService.consultaSolicitudCygnus({Pws_Identificacion: sign.numIdentification,
                                Pws_Tip_Identificacion: sign.typeIdentification!});
                            console.log(resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0]);
                            try{
                                let valIngreso = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Valor_Ingreso")?.value;
                                let cuantoMin = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Quanto3_Minimo")?.value;
                                if (valIngreso !== null && valIngreso !== "0") {
                                    resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = valIngreso+".00";
                                } else if ((valIngreso === null || valIngreso === "0") && 
                                        ( cuantoMin !== null && cuantoMin !== "0")) {
                                    resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = cuantoMin+".00";
                                }
                                let ingresos = resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0];
                                const operFinancieras = {
                                    s_num_solicitud: request.numSol!,
                                    s_identificacion: sign.numIdentification!,
                                    s_tipo_identificacion: sign.typeIdentification,
                                    s_ingreso_principal: ingresos.Pws_Ingreso_Principal,
                                    s_otros_ingresos: ingresos.Pws_otros_ingresos,
                                    s_otros_egresos: ingresos.Pws_Otros_egresos,
                                    s_arriendo: ingresos.Pws_Arriendo,
                                    s_concep_otr_ingre: ingresos.Pws_Concep_otr_ingre,
                                    s_declarante_ren: ingresos.Pws_Declarante_ren,
                                    s_moneda_ext: ingresos.Pws_Moneda_ext,
                                    s_monext_oper: ingresos.Pws_Monext_oper,
                                    s_tip_monext: ingresos.Pws_Tip_monext,
                                    s_cuent_ext: ingresos.Pws_Cuent_ext,
                                    s_cuen_extban: ingresos.Pws_Cuen_extban,
                                    s_cuen_extnum: ingresos.Pws_Cuen_extnum,
                                    s_cuen_extpais: ingresos.Pws_Cuen_extpais,
                                    s_cuen_extciudad: ingresos.Pws_Cuen_extciudad
                                }
                                const valorfinancial=await WLOCreditAPIService.addFinancialInformation(operFinancieras);                                
                            }catch(e){
                            }
                        }
                    }
                     //Guardar Datacredito
                        const data5 = {
                            Pws_num_solicitud: request.numSol!,
                            Pws_Identificacion: sign.numIdentification!,
                            Pws_Tip_Identificacion: sign.typeIdentification!,
                            Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
                            Pws_estado_resotp: estadoDatacredito,
                            Pws_Tip_val: '2',
                            Pws_Puntaje: String(puntaje),
                            Pws_Entidad_Consul: '4',
                            Pws_Num_cuodat: String(cuotaData),
                            Pws_Xml_Dc: JSON.stringify(response3.responseData),
                        };
                        if (estadoTerce.payload.data.ListRef.Datos_Referencias[2].s_descripcion_legal == '1') {
                            const response5 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data5);
                        }
                    
                    //evualua score cygnus
                    if (Number(request.valoresLogin.tipodeudor) == 2) {
                        let valoragregado = {
                            Pws_num_solicitud: request.numSol!,
                            Pws_fec_solicitud_ini: '',
                            Pws_fec_solicitud_fin: '',
                            Pws_Tip_estado: '',
                            Pws_Tip_Consulta: '1',
                        };
                        const consultaSolicitud = await WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado);
                        const solicitud = consultaSolicitud.payload.data['datos_soli']['datos_Sol'][0];
                        const personaRequest = {
                            Pws_linea_credito: solicitud['Pws_Linea_Credito'],
                        };
                        let antiguedadExp: string;
                        if(response3.responseData != undefined){
                            console.log(typeof resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra);
                            if(resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == 1 || resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == "1" ){
                                antiguedadExp = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Cotizaciones_Consecutivos_6m")?.value;
                                if(antiguedadExp != "0"){
                                    const hoy = new Date();
                                    const fechaAntiguedad = new Date(hoy.getFullYear(), hoy.getMonth() - parseInt(antiguedadExp), hoy.getDate());
                                    const formatoFecha = `${fechaAntiguedad.getFullYear()}-${String(fechaAntiguedad.getMonth() + 1).padStart(2, "0")}-${String(fechaAntiguedad.getDate()).padStart(2, "0")}-00.00.00.000000`;
                                    resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp = formatoFecha;
                                }
                            }else if(resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == 2 || resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == "2" ){
                                antiguedadExp = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Meses_Continuidad")?.value;
                                if( antiguedadExp != "0"){
                                    const hoy = new Date();
                                    const fechaAntiguedad = new Date(hoy.getFullYear(), hoy.getMonth() - parseInt(antiguedadExp), hoy.getDate());
                                    const formatoFecha = `${fechaAntiguedad.getFullYear()}-${String(fechaAntiguedad.getMonth() + 1).padStart(2, "0")}-${String(fechaAntiguedad.getDate()).padStart(2, "0")}-00.00.00.000000`;
                                    resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp = formatoFecha;
                                }
                            }
                        }else{
                            const jsonObject = JSON.parse(response3.payload.data.datos_datacre.datos_datacredito_consult[0].c_Pws_Xml_Dc);
                            if(resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == "1" ){
                                antiguedadExp = jsonObject?.engineResponse?.find((item: { key: string, value: any }) => item.key === "Cotizaciones_Consecutivos_6m").value;
                                if(antiguedadExp != "0"){
                                    const hoy = new Date();
                                    const fechaAntiguedad = new Date(hoy.getFullYear(), hoy.getMonth() - parseInt(antiguedadExp), hoy.getDate());
                                    const formatoFecha = `${fechaAntiguedad.getFullYear()}-${String(fechaAntiguedad.getMonth() + 1).padStart(2, "0")}-${String(fechaAntiguedad.getDate()).padStart(2, "0")}-00.00.00.000000`;
                                    resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp = formatoFecha;
                                }
                            }else if(resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == "2" ){
                                antiguedadExp = jsonObject?.engineResponse?.find((item: { key: string, value: any }) => item.key === "Meses_Continuidad").value;
                                if(antiguedadExp != "0"){
                                    const hoy = new Date();
                                    const fechaAntiguedad = new Date(hoy.getFullYear(), hoy.getMonth() - parseInt(antiguedadExp), hoy.getDate());
                                    const formatoFecha = `${fechaAntiguedad.getFullYear()}-${String(fechaAntiguedad.getMonth() + 1).padStart(2, "0")}-${String(fechaAntiguedad.getDate()).padStart(2, "0")}-00.00.00.000000`;
                                    resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp = formatoFecha;
                                }
                            }
                        }                        
                        const result = await WLOCreditAPIService.addSolicitudPersona(resultConsultaPersona.payload.data.datos_persona.Datos_persona[0]); 
                        const cygnus=await WLOCreditAPIService.grabar_cignus({
                            Pws_Identificacion :sign.numIdentification!,
                            Pws_Tip_Identificacion:sign.typeIdentification!,
                            Pws_Tip_Perfil:resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_Perfil
                        })  

                        const response10 = await WLOCreditAPIService.EvaluaScore({
                            ps_radicado: DatosDeudor.radicado.Pws_Radic_Codeu,
                            ps_identificacion: String(sign.numIdentification),
                            ps_cod_lin: personaRequest.Pws_linea_credito,
                            ps_tipoCliente: '3',
                            ps_tip_deucode: '3',
                        });

                        scorepuntaje = response10.payload.data.puntaje_obtenido
                    }
                    //Guarda Sagrilaft
                    if (estadoTerce.payload.data.ListRef.Datos_Referencias[1].s_descripcion_legal == '1') {
                        const response2 = await WLOCreditAPIService.regDataCreditoAndSagrilaft({
                            Pws_num_solicitud: request.numSol!,
                            Pws_Identificacion: sign.numIdentification!,
                            Pws_Tip_Identificacion: sign.typeIdentification!,
                            Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
                            Pws_estado_resotp: sagrilaftRiesgo,
                            Pws_Tip_val: '1',
                            Pws_Puntaje: '0',
                            Pws_Entidad_Consul: '2',
                            Pws_Num_cuodat: '',
                            Pws_Xml_Dc: '',
                        });
                    }
                    //Guarda Score Cygnus
                    let estadocygnus = '0';
                    if (scorepuntaje >= 50) {
                        estadocygnus = '0';
                    } else {
                        estadocygnus = '1';
                    }
                    const data6 = {
                        Pws_num_solicitud: request.numSol!,
                        Pws_Identificacion: sign.numIdentification!,
                        Pws_Tip_Identificacion: sign.typeIdentification!,
                        Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
                        Pws_estado_resotp: estadocygnus,
                        Pws_Tip_val: '4',
                        Pws_Puntaje: String(scorepuntaje),
                        Pws_Entidad_Consul: '5',
                        Pws_Num_cuodat: '',
                        Pws_Xml_Dc: '',
                    };
                    const response6 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data6);

                    //Guarda el documento Sagrilaft
                    const downloadDocument = await AuthService.documentSagrilaft({ idDatoConsultado: response.payload.idDatoConsultado });
                    documentRequest = {
                        Pws_num_solicitud: request.numSol!,
                        Pws_Identificacion: sign.numIdentification!,
                        Pws_Tip_Identificacion: sign.typeIdentification!,
                        Pws_Doc_Tipo: '4',
                        Pws_Doc_nombre: response.payload.idDatoConsultado + '.pdf',
                        Pws_Doc_estado: '1',
                        Pws_Doc_ruta: '1',
                        Pws_Clave_doc: 'data:application/pdf;base64,' + downloadDocument.payload,
                    };
                }
                const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
                if (Number(request.valoresLogin.tipodeudor) == 2) {
                    const solicitudResulado = await WLOCreditAPIService.addSolicitud({
                        Pws_num_solicitud: request.numSol!,
                        Pws_Identificacion: sign.numIdentification!,
                        Pws_Tip_Identificacion: sign.typeIdentification!,
                    });
                    console.log("puntaje:", puntaje);
                    console.log("sagrilaftRiesgo:", sagrilaftRiesgo);
                    console.log("scorepuntaje:", scorepuntaje);

                    // APROBADO CODEUDOR
                    if (puntaje >= 500 && sagrilaftRiesgo === '0' && scorepuntaje > 50) {
                        console.log('aprobado');
                        
                        presentaRiesgos = false;
                        valorDataCreditoCodeudor = false;
                        negado = false;
                        enestudio = false;
                        solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado = '4';
                        console.log("Codeudor Aprobado. Puntaje:", puntaje, "Score:", scorepuntaje);
                    }
                    // CODEUDOR REQUERIDO
                    else if (scorepuntaje > 50 && (puntaje < 500 || sagrilaftRiesgo !== '0')) {
                        console.log('codeudor requerido');
                        
                        presentaRiesgos = false;
                        valorDataCreditoCodeudor = true;
                        negado = false;
                        enestudio = false;
                        solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado = '7';
                        
                    }
                    // NEGADO
                    else if (scorepuntaje <= 50) {
                        console.log('negado');
                        
                        presentaRiesgos = false;
                        valorDataCreditoCodeudor = false;
                        negado = true;
                        enestudio = false;
                        solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado = '5';
                        console.log("Codeudor Negado. Score:", scorepuntaje);
                    }
                    if ((solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado == '4')) {                            
                        if (tipodocumento === 'ti') tipodocumento = '12';
                        if (tipodocumento === 'cc') tipodocumento = '13';
                        if (tipodocumento === 'ce') tipodocumento = '22';
                        if (tipodocumento === 'nit') tipodocumento = '31';
                        if (tipodocumento === 'pa') tipodocumento = '41';

                        let codeudors1: any = {
                            Pws_codigo: pws_codigo,
                            Pws_num_solicitud: Number(request.numSol!),
                            Pws_Identificacion: DatosDeudor.radicado.Pws_Identificacion,
                            Pws_Radic_Codeu: Number(request.numSol!),
                            Pws_Tip_Identificacion: tipodocumento,
                            Pws_Iden_Code: sign.numIdentification!,
                            Pws_Tip_Identificacion_Code: '13',
                            Pws_Tip_Codeu: '2',
                            Pws_Estado: '4',
                        };
                        const allCodeudor = await WLOCreditAPIService.addCodeudor(codeudors1);
                    } else {
                        presentaRiesgos = true;
                        if (tipodocumento === 'ti') tipodocumento = '12';
                        if (tipodocumento === 'cc') tipodocumento = '13';
                        if (tipodocumento === 'ce') tipodocumento = '22';
                        if (tipodocumento === 'nit') tipodocumento = '31';
                        if (tipodocumento === 'pa') tipodocumento = '41';
                        
                        let codeudors2: any = {
                            Pws_codigo: pws_codigo,
                            Pws_num_solicitud: Number(request.numSol!),
                            Pws_Identificacion: DatosDeudor.radicado.Pws_Identificacion,
                            Pws_Radic_Codeu: Number(request.numSol!),
                            Pws_Tip_Identificacion: tipodocumento,
                            Pws_Iden_Code: sign.numIdentification!,
                            Pws_Tip_Identificacion_Code: '13',
                            Pws_Tip_Codeu: '2',
                            Pws_Estado: '5',
                        };
                        const allCodeudor = await WLOCreditAPIService.addCodeudor(codeudors2);
                        console.log(allCodeudor);
                        
                    }
                }
                console.log("1, entrada");
                
                navigate(
                    AppRoutes.VIEW_DOCUMENT_SIGN +
                    '?' +
                    (presentaRiesgos ? '1' : '0') +
                    '-' +
                    (negado ? '1' : '0') +
                    '-' +
                    (valorDataCreditoCodeudor ? '1' : '0') +
                    '-' +
                    (enestudio ? '1' : '0'),
                    { replace: true }
                );
            } else if (
                allCodeudor.payload.data.Resultado !== '-2' &&
                allCodeudor.payload.data.Resultado !== '-1' &&
                allCodeudor.payload.data.datos_codeu.Datos_codeudor[0].Pws_Tip_Codeu === '1'
            ) {
                propietario = true;
                const propietarioString = propietario.toString();

                try {
                    if (allCodeudor.payload.data.Resultado === '1') {
                        const codeudorPropietario = localStorage.setItem('propietario', propietarioString);
                        if (tipodocumento === '12') tipodocumento = 'ti';
                        if (tipodocumento === '13') tipodocumento = 'cc';
                        if (tipodocumento === '22') tipodocumento = 'ce';
                        if (tipodocumento === '31') tipodocumento = 'nit';
                        if (tipodocumento === '41') tipodocumento = 'pa';

                        const data = {
                            datoConsultar: sign.numIdentification!, //19123402
                            tipoDocumento: tipodocumento,
                            numeroSolicitud: request.numSol!, //No tenemos un ws que nos diga el nro de solicitud
                            fechaSolicitud: Moment(new Date()).format('MM/DD/YYYY'),
                            email: `${sign.email!}`,
                        };
                        if (estadoTerce.payload.data.ListRef.Datos_Referencias[1].s_descripcion_legal == '1') {
                            const response = await WLOCreditAPIService.consultaConsolidada(data);
                            let valorDataCreditoCodeudor = 0;
                            let enestudio = 0;
                            let negado = 0;
                            let estadosargrilaft
                            if (response.payload.presentaRiesgo) {
                                sagrilaftRiesgo = '1';
                                presentaRiesgos = true;
                                if (tipodocumento === 'ti') tipodocumento = '12';
                                if (tipodocumento === 'cc') tipodocumento = '13';
                                if (tipodocumento === 'ce') tipodocumento = '22';
                                if (tipodocumento === 'nit') tipodocumento = '31';
                                if (tipodocumento === 'pa') tipodocumento = '41';

                                let codeudors1: any = {
                                    Pws_codigo: pws_codigo,
                                    Pws_num_solicitud: Number(request.numSol!),
                                    Pws_Identificacion: DatosDeudor.radicado.Pws_Identificacion,
                                    Pws_Radic_Codeu: Number(request.numSol!),
                                    Pws_Tip_Identificacion: tipodocumento,
                                    Pws_Iden_Code: sign.numIdentification!,
                                    Pws_Tip_Identificacion_Code: '13',
                                    Pws_Tip_Codeu: '1',
                                    Pws_Estado: '5',
                                };
                                const allCodeudor = await WLOCreditAPIService.addCodeudor(codeudors1);
                            } else {
                                sagrilaftRiesgo = '0';
                                presentaRiesgos = false;
                                if (tipodocumento === 'ti') tipodocumento = '12';
                                if (tipodocumento === 'cc') tipodocumento = '13';
                                if (tipodocumento === 'ce') tipodocumento = '22';
                                if (tipodocumento === 'nit') tipodocumento = '31';
                                if (tipodocumento === 'pa') tipodocumento = '41';

                                let codeudors2: any = {
                                    Pws_codigo: pws_codigo,
                                    Pws_num_solicitud: Number(request.numSol!),
                                    Pws_Identificacion: DatosDeudor.radicado.Pws_Identificacion,
                                    Pws_Radic_Codeu: Number(request.numSol!),
                                    Pws_Tip_Identificacion: tipodocumento,
                                    Pws_Iden_Code: sign.numIdentification!,
                                    Pws_Tip_Identificacion_Code: '13',
                                    Pws_Tip_Codeu: '1',
                                    Pws_Estado: '4',
                                };
                                const allCodeudor = await WLOCreditAPIService.addCodeudor(codeudors2);
                            }
                            const response2 = await WLOCreditAPIService.regDataCreditoAndSagrilaft({
                                Pws_num_solicitud: request.numSol!,
                                Pws_Identificacion: sign.numIdentification!,
                                Pws_Tip_Identificacion: sign.typeIdentification!,
                                Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
                                Pws_estado_resotp: sagrilaftRiesgo,
                                Pws_Tip_val: '1',
                                Pws_Puntaje: sagrilaftRiesgo,
                                Pws_Entidad_Consul: '2',
                                Pws_Num_cuodat: '',
                                Pws_Xml_Dc: '',
                            });
                            //Obtiene y Guarda documento Sagrilaft
                            const downloadDocument = await AuthService.documentSagrilaft({ idDatoConsultado: response.payload.idDatoConsultado });
                            documentRequest = {
                                Pws_num_solicitud: request.numSol!,
                                Pws_Identificacion: sign.numIdentification!,
                                Pws_Tip_Identificacion: sign.typeIdentification!,
                                Pws_Doc_Tipo: '4',
                                Pws_Doc_nombre: response.payload.idDatoConsultado + '.pdf',
                                Pws_Doc_estado: '1',
                                Pws_Doc_ruta: '1',
                                Pws_Clave_doc: 'data:application/pdf;base64,' + downloadDocument.payload,
                            };
                            console.log(downloadDocument);
                            
                            console.log("handleDownload called");
                            console.log("Documento URL:", documento); // Verificar el contenido de documento
                            const link = document.createElement("a");
                            link.href = documentRequest.Pws_Clave_doc;
                            link.download = "Formato Conocimiento Sagrilaft.pdf";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);   

                              console.log("2, entrada");
                            navigate(
                                AppRoutes.VIEW_DOCUMENT_SIGN +
                                '?' +
                                (presentaRiesgos ? '1' : '0') +
                                '-' +
                                negado +
                                '-' +
                                valorDataCreditoCodeudor +
                                '-' +
                                enestudio,
                                { replace: true }
                            );
                        }
                    }
                    if (allCodeudor.payload.data.Resultado == '-1') {
                        toast.error('Faltan datos verifique');
                    }
                    if (allCodeudor.payload.data.Resultado == '0') {
                        console.log('No se encontro el credito en cygnus, Verifique');
                    }
                } catch (error) {
                    toast.error('Se produjo un error de conexion con el servicio');
                }
            } else {
                propietario = false;
                const propietarioString = propietario.toString();
                const codeudorPropietario = localStorage.setItem('propietario', propietarioString);
                const Requerido = '0';
                const codeudorRequerido = localStorage.setItem('Requerido', Requerido);
                if (tipodocumento === '12') tipodocumento = 'ti';
                if (tipodocumento === '13') tipodocumento = 'cc';
                if (tipodocumento === '22') tipodocumento = 'ce';
                if (tipodocumento === '31') tipodocumento = 'nit';
                if (tipodocumento === '41') tipodocumento = 'pa';
                // consulta sagrilaft
                const data = {
                    datoConsultar: sign.numIdentification!, //19123402
                    tipoDocumento: tipodocumento,
                    numeroSolicitud: request.numSol!, //No tenemos un ws que nos diga el nro de solicitud
                    fechaSolicitud: Moment(new Date()).format('MM/DD/YYYY'),
                    email: `${sign.email!}`,
                };
                //Consulta Terceros
                console.log(estadoTerce.payload.data.ListRef.Datos_Referencias[1].s_descripcion_legal);
                
                if (estadoTerce.payload.data.ListRef.Datos_Referencias[1].s_descripcion_legal == '1') {
                    const response = await WLOCreditAPIService.consultaConsolidada(data);
                    if (response.payload.presentaRiesgo) {
                        sagrilaftRiesgo = '1';
                        presentaRiesgos = true;
                    } else {
                        sagrilaftRiesgo = '0';
                        presentaRiesgos = false;
                    }

                    //consulta datacredito
                    let consulta;
                    if (request.personCygnus?.Pws_Apellidos1 == '') {
                        let sends: WloCreditoService.SolicitudDetalla = {
                            Pws_Num_Solicitud: request.numSol!,
                            Pws_Identificacion: sign.numIdentification!,
                            Pws_Tip_Identificacion: String(
                                typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.filter(
                                    (valor) => valor.s_codigo == Number(sign.typeIdentification!)
                                )[0].s_datacredito
                            ),
                            Pws_Perfil_Acceso: '',
                            Pws_Fec_Solicitud: '',
                        };
                        consulta = await WLOCreditAPIService.consultaSolicitudCygnusDetal(sends);
                    }
                    let apellido;
                    if (request.personCygnus?.Pws_Apellidos1 == '') {
                        apellido = consulta.payload.data.datos_persona.Datos_persona[0].Pws_Apellidos1;
                    } else {
                        apellido = request.personCygnus?.Pws_Apellidos1;
                    }
                    const data4 = {
                        identificacion: sign.numIdentification!,
                        primer_apellido: apellido,
                        tipoIdentificacion: String(
                            typeIdentification.WS_TIPOS_IDENTIFICACION_IResult.LstTiposIdent.Datos_TiposIdentificacion.filter(
                                (valor) => valor.s_codigo == Number(sign.typeIdentification!)
                            )[0].s_datacredito
                        ),
                    };
                    let response3;
                    let estadoDatacredito = '0';
                    let consultaDatacredito : WloCreditoService.ConsultaDatacreditoCygnus={
                        Pws_Identificacion: sign.numIdentification!,
                        Pws_Tip_Identificacion: "13",
                        Pws_Tip_val:"2",
                    };
                    response3 = await WLOCreditAPIService.consultaDatacredito(consultaDatacredito);
                    console.dir(response3)
                    
                    
                    if (response3.payload.data.Pws_R_s_cod == 1){
                       
                        const jsonObject = JSON.parse(response3.payload.data.datos_datacre.datos_datacredito_consult[0].c_Pws_Xml_Dc)

                        if (jsonObject != null && jsonObject != undefined) {
                            puntaje = Number(jsonObject?.score?.reasonCodes?.[0]);
                            cuotaData = jsonObject?.engineResponse?.find((item: { key: string, value: any }) => item.key === "Gasto_Financiero").value;
                            if (puntaje >= 1 && puntaje < 500) {
                                estadoDatacredito = '1';
                            } else if (puntaje >= 500) {
                                estadoDatacredito = '0';
                            }
                            const data5 = {
                                Pws_num_solicitud: request.numSol!,
                                Pws_Identificacion: sign.numIdentification!,
                                Pws_Tip_Identificacion: sign.typeIdentification!,
                                Pws_Fec_gen: String(response3.payload.data.datos_datacre.datos_datacredito_consult[0].Pws_Fec_gen),             
                                Pws_estado_resotp: estadoDatacredito,
                                Pws_Tip_val: '2',
                                Pws_Puntaje: String(puntaje),
                                Pws_Entidad_Consul: '4',
                                Pws_Num_cuodat: String(cuotaData),
                                Pws_Xml_Dc: JSON.stringify(jsonObject),
                            };
                            const response5 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data5);

                            const resultConsultaPersona = await WLOCreditAPIService.consultaSolicitudCygnus({Pws_Identificacion: sign.numIdentification,
                                Pws_Tip_Identificacion: sign.typeIdentification!});
                            console.log(resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0]);
                            console.log(jsonObject);
                            try{
                                let valIngreso = jsonObject.engineResponse.find((item: { key: string, value: any }) => item.key === "Valor_Ingreso")?.value;
                                let cuantoMin = jsonObject.engineResponse.find((item: { key: string, value: any }) => item.key === "Quanto3_Minimo")?.value;
                                if (valIngreso !== null && valIngreso !== "0") {
                                    resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = valIngreso+".00";
                                } else if ((valIngreso === null || valIngreso === "0") && 
                                        ( cuantoMin !== null && cuantoMin !== "0")) {
                                    resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = cuantoMin+".00";
                                }
                                let ingresos = resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0];
                                const operFinancieras = {
                                    s_num_solicitud: request.numSol!,
                                    s_identificacion: sign.numIdentification!,
                                    s_tipo_identificacion: sign.typeIdentification,
                                    s_ingreso_principal: ingresos.Pws_Ingreso_Principal,
                                    s_otros_ingresos: ingresos.Pws_otros_ingresos,
                                    s_otros_egresos: ingresos.Pws_Otros_egresos,
                                    s_arriendo: ingresos.Pws_Arriendo,
                                    s_concep_otr_ingre: ingresos.Pws_Concep_otr_ingre,
                                    s_declarante_ren: ingresos.Pws_Declarante_ren,
                                    s_moneda_ext: ingresos.Pws_Moneda_ext,
                                    s_monext_oper: ingresos.Pws_Monext_oper,
                                    s_tip_monext: ingresos.Pws_Tip_monext,
                                    s_cuent_ext: ingresos.Pws_Cuent_ext,
                                    s_cuen_extban: ingresos.Pws_Cuen_extban,
                                    s_cuen_extnum: ingresos.Pws_Cuen_extnum,
                                    s_cuen_extpais: ingresos.Pws_Cuen_extpais,
                                    s_cuen_extciudad: ingresos.Pws_Cuen_extciudad
                                }
                                const valorfinancial=await WLOCreditAPIService.addFinancialInformation(operFinancieras);                                
                            }catch(e){
                            }
                        }
                        
                    }else{
                        
                    try{
                        response3 = await WLOCreditAPIService.consultDataCredito2(data4);
                    }catch(e){
                        console.log(e);
                    }
                    if (response3 != null || response3 != undefined) {
                        if (response3.responseData.Fault != null || response3.responseData.Fault != undefined) {
                            puntaje = 0;
                            cuotaData = 0;
                            estadoDatacredito = '2';
                        } else {
                            puntaje = response3.responseData.score.reasonCodes[0];
                            cuotaData = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Gasto_Financiero").value;                           

                            if (puntaje >= 1 && puntaje < 500) {
                                estadoDatacredito = '1';
                            } else if (puntaje >= 500) {
                                estadoDatacredito = '0';
                            }
                            const resultConsultaPersona = await WLOCreditAPIService.consultaSolicitudCygnus({Pws_Identificacion: sign.numIdentification,
                                Pws_Tip_Identificacion: sign.typeIdentification!});
                            try{
                                let valIngreso = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Valor_Ingreso")?.value;
                                let cuantoMin = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Quanto3_Minimo")?.value;
                                if (valIngreso !== null && valIngreso !== "0") {
                                    resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = valIngreso+".00";
                                } else if ((valIngreso === null || valIngreso === "0") && 
                                        ( cuantoMin !== null && cuantoMin !== "0")) {
                                    resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = cuantoMin+".00";
                                }
                                let ingresos = resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0];
                                const operFinancieras = {
                                    s_num_solicitud: request.numSol!,
                                    s_identificacion: sign.numIdentification!,
                                    s_tipo_identificacion: sign.typeIdentification,
                                    s_ingreso_principal: ingresos.Pws_Ingreso_Principal,
                                    s_otros_ingresos: ingresos.Pws_otros_ingresos,
                                    s_otros_egresos: ingresos.Pws_Otros_egresos,
                                    s_arriendo: ingresos.Pws_Arriendo,
                                    s_concep_otr_ingre: ingresos.Pws_Concep_otr_ingre,
                                    s_declarante_ren: ingresos.Pws_Declarante_ren,
                                    s_moneda_ext: ingresos.Pws_Moneda_ext,
                                    s_monext_oper: ingresos.Pws_Monext_oper,
                                    s_tip_monext: ingresos.Pws_Tip_monext,
                                    s_cuent_ext: ingresos.Pws_Cuent_ext,
                                    s_cuen_extban: ingresos.Pws_Cuen_extban,
                                    s_cuen_extnum: ingresos.Pws_Cuen_extnum,
                                    s_cuen_extpais: ingresos.Pws_Cuen_extpais,
                                    s_cuen_extciudad: ingresos.Pws_Cuen_extciudad
                                }
                                const valorfinancial=await WLOCreditAPIService.addFinancialInformation(operFinancieras);                                
                            }catch(e){
                            }
                        }
                    } else {
                        puntaje = response3.responseData.score.reasonCodes[0];
                        cuotaData = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Gasto_Financiero").value;                           
                        estadoDatacredito = '3';                        
                        try{
                            let valIngreso = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Valor_Ingreso")?.value;
                            let cuantoMin = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Quanto3_Minimo")?.value;
                            if (valIngreso !== null && valIngreso !== "0") {
                                resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = valIngreso+".00";
                            } else if ((valIngreso === null || valIngreso === "0") && 
                                    ( cuantoMin !== null && cuantoMin !== "0")) {
                                resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0].Pws_Ingreso_Principal = cuantoMin+".00";
                            }
                            let ingresos = resultConsultaPersona.payload.data.datos_operfinan.Datos_operfinan[0];
                            const operFinancieras = {
                                s_num_solicitud: request.numSol!,
                                s_identificacion: sign.numIdentification!,
                                s_tipo_identificacion: sign.typeIdentification,
                                s_ingreso_principal: ingresos.Pws_Ingreso_Principal,
                                s_otros_ingresos: ingresos.Pws_otros_ingresos,
                                s_otros_egresos: ingresos.Pws_Otros_egresos,
                                s_arriendo: ingresos.Pws_Arriendo,
                                s_concep_otr_ingre: ingresos.Pws_Concep_otr_ingre,
                                s_declarante_ren: ingresos.Pws_Declarante_ren,
                                s_moneda_ext: ingresos.Pws_Moneda_ext,
                                s_monext_oper: ingresos.Pws_Monext_oper,
                                s_tip_monext: ingresos.Pws_Tip_monext,
                                s_cuent_ext: ingresos.Pws_Cuent_ext,
                                s_cuen_extban: ingresos.Pws_Cuen_extban,
                                s_cuen_extnum: ingresos.Pws_Cuen_extnum,
                                s_cuen_extpais: ingresos.Pws_Cuen_extpais,
                                s_cuen_extciudad: ingresos.Pws_Cuen_extciudad
                            }
                            const valorfinancial=await WLOCreditAPIService.addFinancialInformation(operFinancieras);                                
                        }catch(e){
                        }
                    }
                    
                }
                    //Guardar Datacredito
                    const data5 = {
                        Pws_num_solicitud: request.numSol!,
                        Pws_Identificacion: sign.numIdentification!,
                        Pws_Tip_Identificacion: sign.typeIdentification!,
                        Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
                        Pws_estado_resotp: estadoDatacredito,
                        Pws_Tip_val: '2',
                        Pws_Puntaje: String(puntaje),
                        Pws_Entidad_Consul: '4',
                        Pws_Num_cuodat: String(cuotaData),
                        Pws_Xml_Dc: JSON.stringify(response3.responseData),
                    };
                    if (estadoTerce.payload.data.ListRef.Datos_Referencias[2].s_descripcion_legal == '1') {
                        const response5 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data5);
                    }
                    //evualua score cygnus
                    if (Number(request.valoresLogin.tipodeudor) < 2) {
                        try{
                        let antiguedadExp: string;
                        if(response3.responseData != undefined){
                            console.log(typeof resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra);
                            if(resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == 1 || resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == "1" ){
                                antiguedadExp = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Cotizaciones_Consecutivos_6m")?.value;
                                if(antiguedadExp != "0"){
                                    const hoy = new Date();
                                    const fechaAntiguedad = new Date(hoy.getFullYear(), hoy.getMonth() - parseInt(antiguedadExp), hoy.getDate());
                                    const formatoFecha = `${fechaAntiguedad.getFullYear()}-${String(fechaAntiguedad.getMonth() + 1).padStart(2, "0")}-${String(fechaAntiguedad.getDate()).padStart(2, "0")}-00.00.00.000000`;
                                    resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp = formatoFecha;
                                }
                            }else if(resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == 2 || resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == "2" ){
                                antiguedadExp = response3.responseData.engineResponse.find((item: { key: string, value: any }) => item.key === "Meses_Continuidad")?.value;
                                if( antiguedadExp != "0"){
                                    const hoy = new Date();
                                    const fechaAntiguedad = new Date(hoy.getFullYear(), hoy.getMonth() - parseInt(antiguedadExp), hoy.getDate());
                                    const formatoFecha = `${fechaAntiguedad.getFullYear()}-${String(fechaAntiguedad.getMonth() + 1).padStart(2, "0")}-${String(fechaAntiguedad.getDate()).padStart(2, "0")}-00.00.00.000000`;
                                    resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp = formatoFecha;
                                }
                            }
                        }else{
                            const jsonObject = JSON.parse(response3.payload.data.datos_datacre.datos_datacredito_consult[0].c_Pws_Xml_Dc);
                            if(resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == "1" ){
                                antiguedadExp = jsonObject?.engineResponse?.find((item: { key: string, value: any }) => item.key === "Cotizaciones_Consecutivos_6m").value;
                                if(antiguedadExp != "0"){
                                    const hoy = new Date();
                                    const fechaAntiguedad = new Date(hoy.getFullYear(), hoy.getMonth() - parseInt(antiguedadExp), hoy.getDate());
                                    const formatoFecha = `${fechaAntiguedad.getFullYear()}-${String(fechaAntiguedad.getMonth() + 1).padStart(2, "0")}-${String(fechaAntiguedad.getDate()).padStart(2, "0")}-00.00.00.000000`;
                                    resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp = formatoFecha;
                                }
                            }else if(resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_contra == "2" ){
                                antiguedadExp = jsonObject?.engineResponse?.find((item: { key: string, value: any }) => item.key === "Meses_Continuidad").value;
                                if(antiguedadExp != "0"){
                                    const hoy = new Date();
                                    const fechaAntiguedad = new Date(hoy.getFullYear(), hoy.getMonth() - parseInt(antiguedadExp), hoy.getDate());
                                    const formatoFecha = `${fechaAntiguedad.getFullYear()}-${String(fechaAntiguedad.getMonth() + 1).padStart(2, "0")}-${String(fechaAntiguedad.getDate()).padStart(2, "0")}-00.00.00.000000`;
                                    resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_fec_ingemp = formatoFecha;
                                }
                            }
                        }                        
                        const result = await WLOCreditAPIService.addSolicitudPersona(resultConsultaPersona.payload.data.datos_persona.Datos_persona[0]); 
                        const cygnus=await WLOCreditAPIService.grabar_cignus({
                            Pws_Identificacion :sign.numIdentification!,
                            Pws_Tip_Identificacion:sign.typeIdentification!,
                            Pws_Tip_Perfil:resultConsultaPersona.payload.data.datos_persona.Datos_persona[0].Pws_Tip_Perfil
                        }) 
                        }catch(e){
                            console.log(e);
                        }
                    

                        const response10 = await WLOCreditAPIService.EvaluaScore({
                            ps_radicado: request.valoresLogin.radicado,
                            ps_identificacion: String(sign.numIdentification),
                            ps_cod_lin: request.valoresLogin.lineacredito,
                            ps_tipoCliente: request.valoresLogin.tipocliente == '' ? '2' : request.valoresLogin.tipocliente,
                            ps_tip_deucode: "2",
                        });

                        scorepuntaje = response10.payload.data.puntaje_obtenido;
                        const consultaCupo = await WLOCreditAPIService.consultaCupo({
                            Pws_Solicitud: String(localStorage.getItem('num_solicitud')),
                            Pws_Identificacion: String(sign.numIdentification),
                            Pws_num_Tip_Ident: String(sign.typeIdentification!),
                        });
                        localStorage.setItem('cupoRotativo', consultaCupo.payload.data.Cupo);
                    } else {
                        //Si tipodeudor es mayor a 2 es porque es codeudor propietario, no valida score cygnus
                        scorepuntaje = 0;
                        scorecygnus = true;
                    }

                    //Guarda Sagrilaft
                    if (estadoTerce.payload.data.ListRef.Datos_Referencias[1].s_descripcion_legal == '1') {
                        const response2 = await WLOCreditAPIService.regDataCreditoAndSagrilaft({
                            Pws_num_solicitud: request.numSol!,
                            Pws_Identificacion: sign.numIdentification!,
                            Pws_Tip_Identificacion: sign.typeIdentification!,
                            Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
                            Pws_estado_resotp: sagrilaftRiesgo,
                            Pws_Tip_val: '1',
                            Pws_Puntaje: '0',
                            Pws_Entidad_Consul: '2',
                            Pws_Num_cuodat: '',
                            Pws_Xml_Dc: '',
                        });
                    }
                    //Guarda Score Cygnus
                    let estadocygnus = '0';
                    if (scorepuntaje >= 51) {
                        estadocygnus = '0';
                    } else {
                        estadocygnus = '1';
                    }
                    const data6 = {
                        Pws_num_solicitud: request.numSol!,
                        Pws_Identificacion: sign.numIdentification!,
                        Pws_Tip_Identificacion: sign.typeIdentification!,
                        Pws_Fec_gen: Moment(new Date()).format('MM/DD/YYYY'),
                        Pws_estado_resotp: estadocygnus,
                        Pws_Tip_val: '4',
                        Pws_Puntaje: String(scorepuntaje),
                        Pws_Entidad_Consul: '5',
                        Pws_Num_cuodat: '',
                        Pws_Xml_Dc: '',
                    };
                    if (estadoTerce.payload.data.ListRef.Datos_Referencias[4].s_descripcion_legal == '1') {
                        const response6 = await WLOCreditAPIService.regDataCreditoAndSagrilaft(data6);
                    }

                    //Guardar documento Sagrilaft
                    const downloadDocument = await AuthService.documentSagrilaft({ idDatoConsultado: response.payload.idDatoConsultado });
                    documentRequest = {
                        Pws_num_solicitud: request.numSol!,
                        Pws_Identificacion: sign.numIdentification!,
                        Pws_Tip_Identificacion: sign.typeIdentification!,
                        Pws_Doc_Tipo: '4',
                        Pws_Doc_nombre: response.payload.idDatoConsultado + '.pdf',
                        Pws_Doc_estado: '1',
                        Pws_Doc_ruta: '1',
                        Pws_Clave_doc: 'data:application/pdf;base64,' + downloadDocument.payload,
                    };
                }
                const solicitud = await WLOCreditAPIService.addDocuments(documentRequest);
                //codeudor o el cliente
                console.log("puntaje:", puntaje);
                        console.log("sagrilaftRiesgo:", sagrilaftRiesgo);
                        console.log("scorepuntaje:", scorepuntaje);
                        console.log(request.valoresLogin.tipodeudor);
                        
                if (Number(request.valoresLogin.tipodeudor) < 2) {
                    const solicitudResulado = await WLOCreditAPIService.addSolicitud({
                        Pws_num_solicitud: request.numSol!,
                        Pws_Identificacion: sign.numIdentification!,
                        Pws_Tip_Identificacion: sign.typeIdentification!,
                    });
                    console.log(solicitudResulado);
                    //En estudio
                    
                    if ((puntaje == 0)) {
                        console.log(5);
                        
                        if ((sagrilaftRiesgo = '1')) {
                            presentaRiesgos = true;
                        } else {
                            presentaRiesgos = false;
                        }
                        valorDataCreditoCodeudor = false;
                        negado = false;
                        enestudio = true;
                        solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado = '12';
                    }

                    //NEGADO
                    else if ((puntaje >= 1 && puntaje <= 49) || scorepuntaje <= 50) {
                        console.log('4');
                        
                        presentaRiesgos = false;
                        valorDataCreditoCodeudor = false;
                        negado = true;
                        enestudio = false;
                        solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado = '5';
                    }
                    //Aprobado
                    else if (puntaje > 500 && (sagrilaftRiesgo == '0') && scorepuntaje > 50) {
                        console.log('3');
                        
                        presentaRiesgos = false;
                        valorDataCreditoCodeudor = false;
                        negado = false;
                        enestudio = false;
                        solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado = '4';

                        
                        
                       /*  const grabaScore = await WLOCreditAPIService.grabarScore({
                            ps_radicado: request.valoresLogin.radicado,
                            ps_identificacion: String(sign.numIdentification),
                            ps_cod_lin: request.valoresLogin.lineacredito,
                            ps_tipoCliente: request.valoresLogin.tipocliente == '' ? '2' : request.valoresLogin.tipocliente,
                            ps_tip_deucode: "2",
                        });
                        console.log(grabaScore); */
                    }

                    //Codeudor Requerido
                    else if ((puntaje >= 150 && puntaje <= 500) || (scorepuntaje >= 50 && scorepuntaje <= 59 && (sagrilaftRiesgo = '0'))) {
                        presentaRiesgos = false;
                        valorDataCreditoCodeudor = true;
                        negado = false;
                        enestudio = false;
                        solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado = '7';
                    }

                    const solicitudResulados = await WLOCreditAPIService.addSolicitud(
                        solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0]
                    );
                    console.log(solicitudResulados);
                    
                } else {
                    console.log('1');
                    
                    let valueTipo = '';
                    let dataCodeudor = request.valoresLogin.radicado;
                    // if(!negado && !presentaRiesgos && !valorDataCreditoCodeudor && puntaje>500){
                    if (!presentaRiesgos && puntaje > 500 && scorepuntaje > 50) {
                        console.log('2');
                        
                        valueTipo = '4';
                        enestudio = false;
                        //cambio de estado a aprobado por el codeudor
                        const solicitudResulado = await WLOCreditAPIService.addSolicitud({
                            Pws_num_solicitud: request.numSol!,
                            Pws_Identificacion: dataCodeudor.Pws_Identificacion!,
                            Pws_Tip_Identificacion: dataCodeudor.Pws_Tip_Identificacion!,
                        });
                        console.log(dataCodeudor);
                     /*    const grabaScore = await WLOCreditAPIService.grabarScore({
                            ps_radicado: request.valoresLogin.radicado,
                            ps_identificacion: String(sign.numIdentification),
                            ps_cod_lin: request.valoresLogin.lineacredito,
                            ps_tipoCliente: request.valoresLogin.tipocliente == '' ? '2' : request.valoresLogin.tipocliente,
                            ps_tip_deucode: "2",
                        });
                        console.log(grabaScore); */
                        solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0].Pws_Tip_Estado = '4';
                        const solicitudResulados = await WLOCreditAPIService.addSolicitud(
                            solicitudResulado.payload.data.Datos_Sol.Datos_Sol[0]
                        );
                        console.log(solicitudResulados);
                        
                        envioCupo();
                    } else if (negado) {
                        valueTipo = '5';
                    } else {
                        valueTipo = '5';
                    }
                    dataCodeudor.Pws_Estado = valueTipo;
                    const solicitudResulado = await WLOCreditAPIService.addCodeudor(dataCodeudor);
                }
                console.log("3, entrada");
                navigate(
                    AppRoutes.VIEW_DOCUMENT_SIGN +
                    '?' +
                    (presentaRiesgos ? '1' : '0') +
                    '-' +
                    (negado ? '1' : '0') +
                    '-' +
                    (valorDataCreditoCodeudor ? '1' : '0') +
                    '-' +
                    (enestudio ? '1' : '0'),
                    { replace: true }
                );
            }
        }
    };
    //guarda el cupo
    const envioCupo = async () => {
        let lineas;
        const valor = await AdminService.getlineascredito();
        lineas = valor.payload.data.ListLineaCredito.Datos_LineasCredito.length;
        let nume = 0;
        let cupoRot;
        let codigo = 0;
        let result;
        let fecha_inicia;

        let valoragregado = {
            Pws_num_solicitud: request.numSol!,
            Pws_fec_solicitud_ini: '',
            Pws_fec_solicitud_fin: '',
            Pws_Tip_estado: '',
            Pws_Tip_Consulta: '1',
        };

        const consultaSolicitud = await WLOCreditAPIService.consultaSolicitudListadoCygnus(valoragregado);
        //.then((data) => {
        //  fecha_inicia = data.payload.data.datos_soli.datos_Sol[0].Pws_fec_solicitud;
        //  result = data.payload.data.datos_soli.datos_Sol[0].Pws_Lincre
        //});

        while (nume < lineas) {
            if (valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_nombre == result) {
                cupoRot = valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_rotativo;
                codigo = valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_codigo;
                setCodigo(valor.payload.data.ListLineaCredito.Datos_LineasCredito[nume].s_codigo);
            }
            nume += 1;
        }
        const cupo = String(localStorage.getItem('cupoRotativo'));
        var d = new Date(),
            dformat = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/');
        const creaCupo = {
            s_r_ident_cliente: String(sign.numIdentification),
            s_r_lin_cred: String(codigo),
            s_r_fec_ini_cupo: String(dformat),
            s_r_fec_fin_cupo: String(dformat),
            s_r_monto_cupo: String(cupo),
        };
        const responde = await WLOCreditAPIService.registraCupo(creaCupo);
    };

    const setCodeValue = (event: any, position: number) => {
        const { target } = event;
        const value = target.value.trim();
        if (isNaN(value) || value.length > 1) return;
        const newCode = JSON.parse(JSON.stringify(code));
        newCode[position] = value;
        setCode(newCode);

        const nextIndex = position + 1;
        if (nextIndex < code.length) {
            const nextInput = document.getElementById(`input-${nextIndex}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const clickVerifyCode = async () => {
        try {
            const codeStringValue = code.join('');
            if (codeStringValue.length < 6) return;
            const data = {
                codes: codeStringValue,
                token,
                identificacion: sign.numIdentification,
                tipoIdentificacion: sign.typeIdentification,
            };
                console.log(data.codes)

            const response = await WLOCreditAPIService.verifyCodeRegOtp(data);
            setSign({ ...sign, signValidated: true, otp: codeStringValue });
            setErrorCode(false);
            setIsSucessful(true);
            await verifyGas();

            //Proceso de guardar documento despues de firmar otp    
            const link = document.createElement('a');
            
        } catch (error: any) {
            const { errors } = error;
            console.log(errors);
            if (errors?.invalidCode || errors?.invalidToken) {
                setSign({ ...sign, signValidated: false });
                setErrorCode(true);
            }
        }
    };

    const sendNewCode = () => {
        sendCode();
        setErrorCode(false);
        setViewNewCode(true);
        setCounter(300);
    };

    const viewInputEnterCode = () => {
        setCode(defaultCode);
        setViewNewCode(false);
    };

    const renderInputEnterCode = () => {
        return (
            <>
                <div>
                    <h3 className="sub-title">
                        <p>Al ingresar el código, aceptas ser consultado en listas restrictivas y sagrilaft.</p>
                        <p>Por favor inserta aquí:</p>
                    </h3>
                    <div className="flex-container-modals p-0 m-0">
                        {code.map((value: string, index: number) => {
                            return (
                                <div key={index}>
                                    <input
                                        id={`input-${index}`} 
                                        value={value}
                                        className={'input-code-general'}
                                        type="text"
                                        onChange={(event) => setCodeValue(event, index)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <h3 className="sub-title">
                        <p>Falta {counter} seg para poder envia de nuevo el codigo</p>
                    </h3>
                    <br />
                    {counter > 0 ? (
                        <>
                            <div className="button-center">
                                <div className="form-know-you-item-button button-red color-red" onClick={clickVerifyCode}>
                                    Continuar
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="button-center">
                                <div className="form-know-you-item-button button-red color-red" onClick={sendNewCode}>
                                    Pedir Codigo
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </>
        );
    };

    const renderErrorCode = () => {
        return (
            <div>
                <h3 className="sub-title color-red">Has ingresado mal el código o tu tiempo para ingresarlo a caducado</h3>
                <br />
                <div className="button-center">
                    <div className="form-know-you-item-button button-red color-red" onClick={sendNewCode}>
                        Enviar otro código
                    </div>
                </div>
            </div>
        );
    };

    const renderNewCode = () => {
        return (
            <div>
                <h3 className="sub-title color-green">Se ha enviado un nuevó código revisa tu correo o celular registrado</h3>
                <br />
                <div className="button-center">
                    <div className="form-know-you-item-button button-green color-green" onClick={viewInputEnterCode}>
                        Aceptar
                    </div>
                </div>
            </div>
        );
    };

    const renderFirmaCorrecta = () => {
        return (
            <div>
                <div className="row">
                    <Check />
                </div>
                <br />
                <h3 className="sub-title color-green">Espera un momento para finalizar el proceso</h3>
                <br />
            </div>
        );
    };

    const renderTemplate = () => {
        let template = <></>;
        if (!isSucessful && !errorCode && !viewNewCode) template = renderInputEnterCode();
        if (viewNewCode) template = renderNewCode();
        if (errorCode && !isSucessful) template = renderErrorCode();
        if (isSucessful) template = renderFirmaCorrecta();
        return template;
    };

    const PreventWindowClose = () => {
        useEffect(() => {
          const handleBeforeUnload = (event:any) => {
            event.preventDefault();
            event.returnValue = '';
          };
      
          window.addEventListener('beforeunload', handleBeforeUnload);
      
          return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
          };
        }, []);
      
        return <div> Esta página no se debe cerrar o actualizar.</div> ;
      };
      
    return (
        <Modal className="modal-otp" {...props} onHide={resetCode} size="xl" aria-labelledby="contained-modal-title-center" centered>
            {/* <Modal.Header closeButton></Modal.Header> */}
            <Modal.Body>{renderTemplate()}</Modal.Body>
        </Modal>
    );
}
